import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

import App from 'app/App';

const root = document.getElementById('root') as HTMLDivElement;
ReactDOM
    .createRoot(root)
    .render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );

reportWebVitals();
