import React, { useCallback } from 'react';
import { DebounceInput } from 'react-debounce-input';

import './StockFilter.css';
import useDepotData from 'depot/hooks/useDepotData';
import usePeerfolioStore from "common/Store";

export default function Filter() {
    const [filterTerm, setFilterTerm]: any = usePeerfolioStore(
        (state: any) => [state.filterTerm, state.setFilterTerm]
    );
    const depotData = useDepotData();

    const focusInput = useCallback((inputElement: any) => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    return (
        <div className={"depot_filter" + (filterTerm ? " active" : "")}>
            <div className="filter_outer_wrapper">
                <div className="filter_inner_wrapper">
                    <DebounceInput
                        debounceTimeout={300}
                        maxLength={30}
                        type="text"
                        placeholder="Filter..."
                        value={filterTerm}
                        inputRef={filterTerm ? focusInput : undefined}
                        onChange={event => setFilterTerm(event.target.value)}
                    />
                    <div className="filterResult" data-match={depotData.stocks.length} data-total={depotData.raw.stocks.length} />
                    <div className="clearResult bx bx-x-circle" title="clear filter" onClick={() => setFilterTerm("")} />
                </div>
            </div>
        </div>
    );
}
