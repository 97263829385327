import React from "react";
import { NavLink, useParams } from 'react-router-dom';
import { CNavbar, CContainer, CNavbarBrand, CNavbarNav, CNavItem } from '@coreui/react';

import './AppHeader.css';
import FolioSelector from 'app/FolioSelector';

export default function Navigation() {
    const { peerfolioKey } = useParams();

    const pages = [
        {
            link: peerfolioKey,
            icon: "bx-table",
            label: "Dashboard",
        },
        {
            link: peerfolioKey + "/account",
            icon: "bxs-user-rectangle",
            label: "Account",
        },
    ];

    return (
        <header>
            <CNavbar colorScheme="light" expand="lg" placement="fixed-top">
                <CContainer fluid>
                    <CNavbarBrand className=" animate-charcter">
                        Peerfol.io
                    </CNavbarBrand>

                    <FolioSelector />

                    <CNavbarNav className="" style={{ flexDirection: "row" }}>
                        {pages.map((page: any) =>
                            <CNavItem className="mx-2" key={page.link + page.label}>
                                <NavLink to={page.link} end 
                                    className={
                                        ({ isActive }: any) => "nav-link " + (isActive ? " active" : "")
                                    } 
                                    preventScrollReset={true}>
                                    <i className={"bx nav-icon " + page.icon}></i>
                                    <span className="nav-name">{page.label}</span>
                                </NavLink>
                            </CNavItem>
                        )}
                    </CNavbarNav>
                </CContainer>
            </CNavbar>
        </header>
    );
}
