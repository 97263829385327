/*eslint @typescript-eslint/no-use-before-define: ["error", { "functions": false, "allowNamedExports": true }]*/
/*eslint no-useless-escape: off */
import { useEffect } from 'react';

import { devLog, isSameDay } from 'common/Utils';
import usePeerfolioStore from 'common/Store';

export {
    useDepotData as default,
    getApiUrl,
    sanitizeName,
}

function useDepotData(peerfolioKey?: any) {
    var [depotData, setDepotData, filterTerm] = usePeerfolioStore(
        (state: any) => [state.depotData, state.setDepotData, state.filterTerm],
    );

    useEffect(() => {
        if (peerfolioKey) {
            if (!depotData || (depotData && depotData.key !== peerfolioKey)) {
                depotData = { key: peerfolioKey, };
                setDepotData(depotData);
                loadDepotData(peerfolioKey, setDepotData);
            }
        }

        const newDepotData = filterDepot(depotData, filterTerm);
        setDepotData(newDepotData);
    }, [filterTerm, depotData, setDepotData, peerfolioKey]);

    return depotData;
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
async function loadDepotData(portfolioKey: string, setDepotDataFn: any) {
    if (!portfolioKey) {
        const errorDepotData = { errors: ["Peerfol.io key missing"] };
        return errorDepotData;
    }

    const apiUrl = getApiUrl("stocks", portfolioKey, { cacheInvalidator: /*1min=*/ ~~(Date.now() / 1000 / 60) });
    // const apiUrl = "/api.json";
    devLog("loadDepotData", "fetch");
    fetch(apiUrl, { cache: "force-cache" }).then(response => response.json())
        .then(data => {
            const sanatizeData = sanatizeDepotData({ ...data });
            sanatizeData.raw = { ...data };
            setDepotDataFn(sanatizeData);
        });
}

function filterDepot(depotData: any, filterTerm: string): any {
    if (!depotData || !depotData.stocks) {
        return depotData;
    }

    depotData.stocks = [...depotData.raw.stocks]; // copy

    if (filterTerm) {
        const keywords: any = filterTerm.toLowerCase().match(/(\-?\"(.*?)\"|(\S+))/gm);
        depotData.stocks = depotData.stocks.filter((stock: any): boolean => {
            const stockJSON = JSON.stringify(stock).toLowerCase();
            const isStockMatching: boolean = keywords.reduce((prevResult: any, keyword: any) => {
                const isPositive = (keyword.at(0) !== "-");
                const trimmedKeyword = keyword.replace(/^\-*\"*(.+)\"*$/, "$1"); // trim  -"..."
                return prevResult && stockJSON.includes(trimmedKeyword) === isPositive;
            }, true);

            return isStockMatching;
        });
    }

    sanatizeDepotData(depotData);

    return depotData;
}

function sanatizeDepotData(depotData: any) {
    if (!depotData || !depotData.title) {
        if (!depotData) {
            depotData = {};
        }
        if (!depotData.errors || depotData.errors.length === 0) {
            depotData.errors = ["Unknown loading error"];
        }
        return depotData;
    }

    if (depotData.key.startsWith("s")) {
        depotData.isShared = true;
    }

    const today = (new Date());
    depotData.value = 0;
    depotData.diffAbs = 0;
    depotData.valueDiffAbsToday = 0;

    depotData.stocks.forEach((stock: any) => {
        if (!stock.fullName) {
            stock.fullName = stock.name.slice();
        }
        stock.name = sanitizeName(stock.name);

        if (depotData.date && !depotData.date.getMonth) {
            depotData.date = new Date(depotData.date);
        }

        const standardTypes = ["Currency", "Crypto", "Commodity", "Index"];
        if (standardTypes.includes(stock.type)) {
            stock.name = "*" + stock.name;
        }

        if (!stock.errors) {
            stock.errors = [];
        }

        if (!stock.type) {
            stock.type = "-";
        }

        if (!stock.market) {
            stock.market = "-";
        }

        stock.priceDiffPer = Math.round(
            stock.priceDiffAbs / (stock.price - stock.priceDiffAbs) * 10000) / 100;

        if (stock.limitTop && stock.price > stock.limitTop) {
            stock.isAboveLimit = true;
        }
        if (stock.limitBottom && stock.price < stock.limitBottom) {
            stock.isBelowLimit = true;
        }

        stock.note = stock.note || "";

        if (!stock.category) {
            const capitalLetterTags = stock.note.match(/^\b[A-Z][A-Z0-9]{2,}\b/g);
            stock.category = undefined;
            if (capitalLetterTags && capitalLetterTags.length > 0) {
                stock.category = capitalLetterTags[0];
                stock.note = stock.note.replace(/^\b[A-Z][A-Z0-9]{2,}\b/, "");
            }
        }

        if (stock.date) {
            if (!stock.date.getMonth) {
                stock.date = new Date(stock.date);
            }
            stock.isDataFromToday = isSameDay(today, stock.date);
            stock.dateAge = (today.getTime() - stock.date.getTime()) / 1000;
            stock.isDataOld = (stock.dateAge > 1800);

            if (!depotData.date
                || !(depotData.date instanceof Date)
                || depotData.date < stock.date) {
                depotData.date = stock.date;
            }
        }

        stock.priceDiffPerToday = stock.isDataFromToday ? stock.priceDiffPer : 0;

        if (stock.count) {
            if (stock.count < 0) {
                stock.errors.push("Quantity below 0");
            }

            depotData.diffAbs += stock.value - stock.buyValue;

            depotData.value += stock.value;
            if (stock.isDataFromToday) {
                depotData.valueDiffAbsToday += stock.valueDiffAbsToday;
            }

            stock.buyDate = new Date(stock.buyDate);
            stock.buyDateAge = (today.getTime() - stock.buyDate.getTime()) / 1000;

            stock.valueDiffAbs = stock.value - stock.buyValue;
            stock.valueDiffPer =
                Math.round(stock.valueDiffAbs / stock.buyValue * 10000) / 100;
        } else {
            stock.count = 0;
            stock.buyPrice = 0;
            stock.buyValue = 0;
            stock.valueDiffAbsToday = 0;
            stock.buyDate = null;
            stock.valueDiffAbs = 0;
            stock.valueDiffPer = 0;
            stock.value = 0;
        }
    });

    depotData.buyValue = depotData.value - depotData.diffAbs;
    if (depotData.value) {
        depotData.diffPer = depotData.diffAbs / depotData.buyValue * 100;
        depotData.valueDiffPerToday = depotData.valueDiffAbsToday / (depotData.value - depotData.valueDiffAbsToday) * 100;
    }

    if (depotData.date) {
        depotData.isDataFromToday = today.getFullYear() === depotData.date.getFullYear()
            && today.getMonth() === depotData.date.getMonth()
            && today.getDate() === depotData.date.getDate();
        depotData.dateAge = (today.getTime() - depotData.date.getTime()) / 1000;
        depotData.isDataOld = (depotData.dateAge > 1800);
    }

    return depotData;
}

function getApiUrl(actionName: string, portfolioKey?: string, params?: any) {
    params = params || {};
    params.portfolio_key = portfolioKey;
    return `https://api.peerfol.io/api/v1/${actionName}?` + new URLSearchParams(params);
}

function sanitizeName(name: string) {
    // add spaces to make regexes work everywhere
    name = " " + name + " ";

    // remove tokens
    const tokensToRemove = [
        "Amundi Index Solutions - ",    // Amundi Index Solutions - Amundi MSCI EM Asia UCITS ETF - EUR ACC
        "Index Solutions Index",        // Amundi Index Solutions Index MSCI World SRI PAB - UCITS ETF DR EUR ACC
        "Austauschkurs",
    ];
    const tokensToRemoveRegEx = new RegExp("(" + tokensToRemove.join('|') + ")", "ig");
    name = name.replace(tokensToRemoveRegEx, "");

    // cut away crap in names (starting from things like AG to the end) 
    const removeTowardTheEnd = [
        " inc\\.",   // Netflix Inc.
        " corp\\.",     // NIKOLA CORP.
        " Corporation ", " Common ", " Stock ",  // Microsoft Corporation Common Stock
        " SE ",      // Delivery Hero SE Namens-Aktien o.N.
        " AG ",      // Software AG
        " ASA ",     // Nel ASA. (ASA=Allmennaksjeselskap, norwegische AG)
        " Group ",   // RTL Group S.A. Actions au Porteur o.N.
        " Holdings ",// PayPal Holdings Inc. Reg. Shares DL -,0001. 
        " Holding ", // Alibaba Group Holding Ltd. Reg.Shs (sp.ADRs)/8 DL-,000025
        " GmbH ",    // DWS Group GmbH & Co. KGaA
        " JSC ",     // Agria Group Holding JSC (Joined Stock Company)
        " Lc ",      // DWS Aktien Strategie Deutschland LC Fonds
        " LLC ",     // News Corp LLC 
        " Ltd ", " Shs ", // Aben Minerals Ltd Registered Shs (Old)
        " Limited ", // BYD Company Limited
        " LtdShs ",  // Adcorp Holdings LtdShs
        " INH ",     // BNP PARIBAS INH. EO 2
        " plc ",     // SHELL PLC EO-07
        " co ", " Ltd ",    // SK Telecom Co Ltd Aktie
        " UCITS ", " ETF ",    // iShares Core MSCI World UCITS ETF USD Acc.
        " SRI ", " PAB ", " Dr ",  // Amundi Index MSCI World SRI PAB ETF DR. (SRI=SocialResponsibleInvestment, PAB=Paris-Aligned Benchmark)
        " S\\.A\\.", // Société Anonyme / Sociedad Anónima / Sociedade Anónima 
        " SPA ",     // Eurotech SPA (=Società per Azioni, it.)
        " cp ",      // Canadian Pacific Railway Ltd. (CP).  (DP=Commercial Paper, Canada)  
        " sk ",      // HOLMEN AB B SK 25

        "Namens-Aktien o\\.N\\.",  // Delivery Hero SE Namens-Aktien o.N.
        "Reg\\.",    // Wayfair Inc. Reg. Shares Cl.A DL -,001
        "Registered Shares",  // salesforce Inc. Registered Shares DL -,001
        "o\\.N\\.",  // Delivery Hero SE Namens-Aktien o.N.
        " ADR",      // Alibaba Group Holding Ltd. Reg.Shs (sp.ADRs)/8 DL-,000025
        " vz\\.",       // Volkswagen (VW) vz. Aktie. / Porsche AG Vz. Aktie
        "inhaber",   // Zalando SE Inhaber-Aktien o.N.
        " ACC",      // iShares Digitalisation UCITS ETF - USD ACC
        " USD ",      // iShares Digitalisation UCITS ETF - USD ACC
        "Pref\\. ADR",   // Petroleo Brasileiro Pref. ADR Aktie
        " Oyj",      // Neste OYJ Aktie
        " kgaa ",    // Merck KGaA Aktie
        "USD DIS",   // iShares DJ Industrial Average UCITS ETF USD Dis. (DE)
        " EO-\\",    // SHELL PLC EO-07
        " Eo",       // SHELL PLC EO-07
        "act\\.",    // CAPGEMINI SE ACT.NOM. (UNSP.ADRS) 1/5/EO 8
        " nv ", "N\\.v\\.",      // ASML NV   ASML N.V.
        " Fund ",    // THE BITCOIN FUND UTS A
        " 1C ",      // Xtrackers MSCI World UCITS ETF 1C USD Acc.
        " SP\\.",    // Sanofi S.A. (Sp. ADRs)
        " A ", "\\'A\\'",  // Berkshire Hathaway A Aktie
        " Cl\\.",    // Snowflake Inc. Reg. Shares Cl.A DL-,0001
        "B\\.v\\.", " Bv ",     // Adyen B.V. Parts Sociales
        " co\\.",    // MERCK CO. DL-,01
        " AB ", " O\\.E\\. ",     // XBT PROVIDER AB O.E.
        " Indikation ",  // S&P500 Indikation (Société Générale)

        // cleanup
        " &#39;",    // ’
        " Company ", " \\(",      // Walt Disney Company (The) Registered Shares DL -,01 (DIS)
        " - ",
        ",",

        // ??
        " Com ",
        " Tech ",
    ];
    const removeTowardTheEndRegEx = new RegExp("(" + removeTowardTheEnd.join('|') + ").*$", "ig");
    name = name.replace(removeTowardTheEndRegEx, "");

    // remove initially added spaces again
    name = name.trim();

    return name;
}
