/* eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }] */
import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts'

import useDepotData, { getApiUrl } from 'depot/hooks/useDepotData';

export {
    useQuotesData as default,
    getFilteredDataTable
}

function useQuotesData() {
    const depotData = useDepotData();
    const [quotesData, setQuotesData]: any = useState(null);

    useEffectOnce(() => {
        loadDataOnce(depotData, setQuotesData);
    });

    return quotesData;
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
async function loadDataOnce(depotData: any, setQuotesData: any) {
    const apiUrl = getApiUrl("quotes", depotData.key, { cacheInvalidator: /*2h=*/ ~~(Date.now() / 1000 / 60 / 120) });
    fetch(apiUrl, { cache: "force-cache" })
        .then(response => response.json())
        .then(data => setQuotesData(data));
}

function getFilteredDataTable(quotesData: any, depotData: any, filterDuration: number) {
    const dataTable: any = new window.google.visualization.DataTable({
        cols: [{ type: "date", label: "Time" }],
    });

    if (!quotesData) {
        return dataTable;
    }

    enhanceQuotesData(quotesData, depotData.stocks, filterDuration);
    addSortedTopAndBotton5(quotesData);
    addColumnsForStocksToDataTable(dataTable, quotesData.sortedStocks);

    if (quotesData.sortedStocks.length) {
        addRowsForStocksToDataTable(dataTable, quotesData.sortedStocks);
        formatRowsDataToPercent(dataTable);
    }

    const dataView = changeResolution(dataTable, filterDuration);
    return dataView;
}

function enhanceQuotesData(quotesData: any, depotStocks: any, filterDuration: number) {
    const minDate: any = Date.now() - filterDuration * 60 * 60 * 24 * 1000;
    const minDateStr = new Date(minDate).toISOString().substr(0, 10);

    Object.entries(quotesData.stocks).forEach(([stockId, quoteData]: any) => {
        const stock = depotStocks.find((stock: any) => stock.id === stockId);

        quoteData.isVisible = Boolean(stock);
        if (quoteData.isVisible && quoteData.quotes) {
            quoteData.buyDateString = stock.buyDate.toISOString().substring(0, 10);
            quoteData.label = stock.name;

            quoteData.performances = {};
            if (quoteData.buyDateString <= minDateStr) {
                quoteData.performances[minDateStr] = 0;
            }

            Object.entries(quoteData.quotes).forEach(([dateString, price]: any) => {
                // TODO: find deeper reason for this hack
                // was required for roberts depot > tesla > "2019-07-08021-05-27"
                if (dateString.length !== 10) {
                    return;
                }

                if (dateString <= minDateStr || dateString <= quoteData.buyDateString) {
                    quoteData.startValue = price;
                }

                if (dateString >= minDateStr && dateString >= quoteData.buyDateString && price) {
                    quoteData.lastDate = dateString;
                    quoteData.performance = price / quoteData.startValue - 1;
                    quoteData.performances[dateString] = quoteData.performance;
                }
            });
        }
    });
}

function changeResolution(dataTable: any, filterDuration: number) {
    const unixTimeWeekOffset: any = 24 * 60 * 60 * 4; // start on MONDAY, 5Jan1970
    const respolutions: any = { 30: 24, 90: 24, 365: (2 * 24), 1825: (5 * 24) };
    const resolutionInHours: any = respolutions[filterDuration] || 24;

    const filter = {
        column: 0,
        test: (value: any, rowId: any, columnId: any, datatable: any) => {
            if (!rowId || rowId > datatable.getNumberOfRows() - 7) {
                return true; // keep first and last row
            }
            const nextValue = datatable.getValue(rowId + 1, columnId);
            const bucketThisRow = (value.getTime() / 1000 - unixTimeWeekOffset) / (60 * 60 * resolutionInHours);
            const bucketNextRow = (nextValue.getTime() / 1000 - unixTimeWeekOffset) / (60 * 60 * resolutionInHours);
            return Math.floor(bucketThisRow) !== Math.floor(bucketNextRow);
        },
    };

    const dataView = new window.google.visualization.DataView(dataTable);
    const visibleRowIds = dataTable.getFilteredRows([filter]);
    dataView.setRows(visibleRowIds);

    return dataView;
}

function addSortedTopAndBotton5(quotesData: any) {
    quotesData.sortedStocks = [...Object.values(quotesData.stocks)]
        .filter((quoteData: any) => quoteData.isVisible)
        .sort((a: any, b: any) => {
            return a.performance < b.performance ? 1 : -1;
        });

    quotesData.sortedStocks.filter((stockInfo: any, index: number) => stockInfo.index = index + 1);

    // only leave top and bottom 5 columns
    if (quotesData.sortedStocks.length > 10) {
        quotesData.sortedStocks.splice(5, quotesData.sortedStocks.length - 10);
    }
}

function addColumnsForStocksToDataTable(dataTable: any, sortedStocks: any) {
    sortedStocks.forEach((column: any) => {
        dataTable.addColumn('number', column.index + ") " + column.label);
        dataTable.addColumn({ type: 'string', role: 'annotation' });
    });
}

function addRowsForStocksToDataTable(dataTable: any, sortedStocks: any) {
    const rowsObj: any = {};

    sortedStocks
        .filter((stockQuote: any) => stockQuote.performances)
        .forEach((stockQuote: any, index: number) => {
            Object.entries(stockQuote.performances).forEach(([dateString, value]: any) => {
                if (!rowsObj[dateString]) {
                    rowsObj[dateString] = [new Date(dateString), ...new Array(sortedStocks.length * 2).fill(null)];
                }

                rowsObj[dateString][1 + index * 2] = value;
                const annotation = (stockQuote.lastDate === dateString ? stockQuote.label.substring(0, 15) : null);
                rowsObj[dateString][1 + index * 2 + 1] = annotation;
            });
        });

    // sort rows by date
    const rows: any = Object.values(rowsObj).sort((a: any, b: any) => {
        return a[0] > b[0] ? 1 : -1;
    });

    dataTable.addRows(rows);
}

function formatRowsDataToPercent(dataTable: any) {
    const formatter = new window.google.visualization.NumberFormat({ pattern: '#,###%', negativeColor: '#FF0000' });
    for (let i = 1; i < dataTable.getNumberOfColumns(); i += 2) {
        formatter.format(dataTable, i);
    }
}
