import React from 'react';

import useDepotData from 'depot/hooks/useDepotData';

export default function ToolsShare() {
    const depotData = useDepotData();

    return (
        <div style={{ padding: "15px" }}>
            {depotData.isShared ?
                <a href={"/" + depotData.key}>1K</a>
                :
                <>
                    <a href={"/" + depotData.key}>Full Read</a>
                    <br /><br />
                    <a href={"/s" + depotData.key.substr(0, 12)}>1K</a>
                </>
            }
        </div>
    );
}
