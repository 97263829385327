import React from "react";

export default function AccountPage() {
    return (
        <>
            <section>
                <header>
                    <div className="tabs">
                        <div className="active">
                            <span className="animate-charcter">Peerfolios</span>
                        </div>
                    </div>
                </header>
                <div>
                    to be implemented
                </div>
            </section>

            <section>
                <header>
                    <div className="tabs">
                        <div className="active">
                            <span className="animate-charcter">Security</span>
                        </div>
                    </div>
                </header>
                <div>
                    to be implemented
                </div>
            </section>

            <section>
                <header>
                    <div className="tabs">
                        <div className="active">
                            <span className="animate-charcter">Peers</span>
                        </div>
                    </div>
                </header>
                <div>
                    to be implemented
                </div>
            </section>
        </>
    )
}
