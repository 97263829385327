import React from "react";

import './DropDown.css';

export default function DropDown({ options, value, setter }: any) {
    return (
        <select value={value} onChange={event => setter(event.target.value)}>
            {options.map(opt)}
        </select>
    );
}

function opt(option: any) {
    return option.options ?
        <optgroup key={option.label} label={option.label}>
            {option.options.map(opt)}
        </optgroup>
        :
        <option key={option.value + option.label} value={option.value}>{option.label}</option>
}
