/*eslint react-hooks/exhaustive-deps: off */
import React from "react";
import classnames from "classnames";

import "./Tabs.css";

export default function Tabs({ options, value, setter, headline }: any) {
    if (headline) {
        options = [{ label: headline }];
    }

    return (
        <div className="tabs">
            {options?.map((option: any) =>
                <div key={option.label || option.value} 
                    className={classnames(
                        {active : String(option.value) === String(value)}
                    )}>  
                    <span
                        className={classnames(
                            {"animate-charcter" : String(option.value) === String(value)}
                        )}      
                        onClick={() => setter ? setter(option.value) : ""}
                    >
                        {option.label || option.value}
                    </span>
                </div>
            )}
        </div>
    );
}
