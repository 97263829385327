/*eslint react-hooks/exhaustive-deps: off */
import React from "react";
import { CAlert } from '@coreui/react';

import './Overview.css';
import { devLog, getRedBlackGreenColorForNumber, getRedWhiteGreenColorForNumber, formatNumber } from 'common/Utils';
import useDepotData from 'depot/hooks/useDepotData';
import Tabs from 'common/components/Tabs';

export default function Overview() {
    devLog("Overview");

    const depotData = useDepotData();

    return (
        <section className="hideIfNoStock">
            <header>
                <Tabs headline="Overview" />
            </header>
            <div className="overview">
                {depotData?.isShared && 
                    <CAlert color="danger">
                        This Peerfolio is <b>scaled to 1000&euro;</b> to allow public sharing.
                        The actual investment value is different.
                    </CAlert>
                }

                <div className="stats">
                    <div className="label">
                        total
                    </div>
                    <div className="label">
                        today
                    </div>


                    <div className="total">
                        {formatNumber(depotData.value, { type: depotData.currency })}
                    </div>
                    <TodayChart depotData={depotData} />


                    <div className="" style={{ color: getRedBlackGreenColorForNumber(depotData.diffAbs * 100) }}>
                        <span>{formatNumber(depotData.diffAbs, { type: depotData.currency, includeSign: true })}</span>
                        <span>{formatNumber(depotData.diffPer, { type: "%", includeSign: true, decimals: 1 })}</span>
                    </div>
                    <div className="newRow" style={{ color: getRedBlackGreenColorForNumber(depotData.valueDiffPerToday * 100) }}>
                        <span>{formatNumber(depotData.valueDiffAbsToday, { type: depotData.currency, includeSign: true })}</span>
                        <span>{formatNumber(depotData.valueDiffPerToday, { type: "%", includeSign: true, decimals: 1 })}</span>
                    </div>

                </div>
            </div>
        </section>
    )
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function TodayChart({ depotData }: any) {
    var zeroPercentLeft = 0;
    const divs = depotData.stocks
        .filter((stock: any) => stock.count)
        .sort((stockA: any, stockB: any) => stockA.priceDiffPerToday - stockB.priceDiffPerToday)
        .map((stock: any) => {
            const width = stock.value / depotData.value * 100;
            if (stock.priceDiffPerToday < 0) {
                zeroPercentLeft += width;
            }
            
            return (
                <div
                    key={stock.id}
                    title={stock.name + (stock.isDataFromToday ? " " + formatNumber(stock.priceDiffPerToday, { type: "%" }) : "")} 
                    className={stock.isDataFromToday ? "isDateFromToday" : ""}
                    style={{
                        width: width + "%",
                        background: getRedWhiteGreenColorForNumber(stock.priceDiffPerToday * 100 / 2),  // up to 2%
                    }}>
                    {width > 3 && stock.symbol}
                </div>
            )
        });
  
    return (
        <div className="todayChart" 
            style={{"--zeroPercentLeft": zeroPercentLeft} as any}>
            {divs}
        </div>
    );
}
