/*eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }]*/
import React from "react";
import ReactDomServer from 'react-dom/server';

export {
    devLog,
    getRedWhiteGreenColorForNumber,
    getRedBlackGreenColorForNumber,
    formatDuration,
    isSameDay,
    formatNumber,
    ucwords,
    getPnlTable,
    getPnlTableHTML,
};

function devLog(...args: any) {
    if (process.env.NODE_ENV === "development") {
        console.log(...args);
    }
}

function getRedWhiteGreenColorForNumber(numberBetweenMinusAndPlus100: number): string {
    numberBetweenMinusAndPlus100 = Math.min(numberBetweenMinusAndPlus100, 100);
    numberBetweenMinusAndPlus100 = Math.max(numberBetweenMinusAndPlus100, -100);
 
    const minColor = 0;
    const maxColor = 255;
    const percentOfColor = Math.abs(numberBetweenMinusAndPlus100);
    var red, green, blue;
  
    if (numberBetweenMinusAndPlus100 < 0) {
        // RED: https://convertingcolors.com/rgb-color-255_0_0.html
        green = blue = Math.round(maxColor - (percentOfColor / 100) * (maxColor - minColor));
        red = Math.round(maxColor - (percentOfColor / 2));
    } else {
        // GREEEN: https://convertingcolors.com/rgb-color-0_100_0.html?search=RGB(0,%20100,%200)
        const greenMinColor = 100;
        green = greenMinColor + (100 - percentOfColor) * (maxColor - greenMinColor) / 100;
        red = blue = maxColor - percentOfColor * (maxColor - minColor) / 100;
    }

    return `rgb(${red},${green},${blue})`;
}

function getRedBlackGreenColorForNumber(numberBetweenMinusAndPlus100: number): string {
    const minColor = 80;
    const maxColor = 230;
    const number = numberBetweenMinusAndPlus100 * (maxColor - minColor) / 100;
    const red = Math.round(Math.min(maxColor, minColor - Math.min(number, 0)));
    const green = Math.round(Math.min(maxColor, minColor + Math.max(number, 0)));
    return `rgb(${red},${green},${minColor})`;
}

function formatDuration(durationInSecond: number): string {
    if (!durationInSecond) {
        return "";
    }

    var diffsFormated: any = [];
    const diffs: any = {
        y: 60 * 60 * 24 * 365,
        w: 60 * 60 * 24 * 7,
        d: 60 * 60 * 24,
        h: 60 * 60,
        m: 60,
        s: 1,
    };

    Object.entries(diffs).forEach(([key, secs]: any) => {
        if (secs < durationInSecond) {
            diffsFormated.push(Math.floor(durationInSecond / secs) + key);
            durationInSecond = durationInSecond % secs;
        }
    });

    return diffsFormated.slice(0, 2).join(" ");
}

function isSameDay(date1: Date, date2: Date): boolean {
    return (
        date1 && date2
        && date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate()
    );
}

type FormatOptions = {
    type?: "%" | "EUR" | "DOL" | "USD",
    decimals?: number,
    includeSign?: boolean,
    useSuffix?: boolean,
};

function formatNumber(number: number, options: FormatOptions = {}): string {
    if (!number) {
        number = 0;
        options.decimals = options?.decimals || 0;
    }

    /*
    * without decimals defined:
    * - 100.123  -> 100
    * - 10.123   -> 10
    * - 1.123    -> 1.1
    * - 0.123    -> 0.12
    */
    if (options.decimals === undefined || typeof options.decimals === "undefined") {
        options.decimals = Math.ceil(Math.max(0, 1 - Math.log10(Math.abs(number))));
    }
    options.decimals = Math.min(options.decimals, 2);

    var suffix = "";
    if (options?.useSuffix && options?.type === "%" && number >= 10000) { // fix 10000% crazyness
        number = Math.round(number) / 1000;
        suffix = "k";
        options.decimals = 0;
    } else if (options?.useSuffix && options?.type !== "%" && Math.abs(number) >= 10000) {
        number = Math.round(number) / 1000;
        suffix = "k";

        /*
          3.456.78 --> 3.456
         23.456.78 --> 23.4k
        123.456.78 --> 123k
        */
        // options.decimals = 1;
        if (Math.abs(number) >= 100) {
            options.decimals = 0;
        }
    }


    const { language, userLanguage }: any = window.navigator;
    const formatter = new Intl.NumberFormat(
        (language || userLanguage),
        {
            minimumFractionDigits: options.decimals,
            maximumFractionDigits: options.decimals,
        }
    );

    return (options?.includeSign && number > 0 ? "+" : "") +
        formatter.format(number) + suffix +
        (options?.type === "%" ? "%" : "") +
        (options?.type === "EUR" ? "€" : "") +
        (options?.type === "DOL" || options?.type === "USD" ? "$" : "");
}

function getPnlTableHTML(options: any): string {
    return ReactDomServer.renderToString(getPnlTable(options));
}

function getPnlTable(options: any) {
    const diff = options.valueEnd - options.valueStart;
    const diffPercent = diff / options.valueStart * 100;

    return (
        <table className="pnlTable">
            <tbody>
                {options.headline && <tr><th colSpan={3}>{options.headline}</th></tr>}
                <tr>
                    <td></td>
                    <td>{formatNumber(options.valueStart, { type: options.valueCurrency, decimals: 0 })}</td>
                    <td className="price">
                        {options.commentStart && options.commentStart}
                        {options.priceStart &&
                            <span>(
                                {options.count && "=" + formatNumber(options.count) + " x "}
                                {formatNumber(options.priceStart, { type: options.priceCurrency, decimals: 2 })}
                            )</span>
                        }
                    </td>
                </tr>
                <tr style={{ color: getRedBlackGreenColorForNumber(diffPercent > 0 ? 70 : -70) }}>
                    <td>{diffPercent < 0 ? "-" : "+"}</td>
                    <td>{formatNumber(Math.abs(diff), { type: options.valueCurrency, decimals: 0 })}</td>
                    <td className="diff">({formatNumber(diffPercent, { type: "%", includeSign: true })})</td>
                </tr>
                <tr>
                    <td className="total" colSpan={2}>{formatNumber(options.valueEnd, { type: options.valueCurrency, decimals: 0 })}</td>
                    <td className="price">
                        {options.priceEnd && "(" + formatNumber(options.priceEnd, { type: options.priceCurrency, decimals: 2 }) + ")"}
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function ucwords(str: string): string {
    const toUpperCaseFn = (s: string) => s.toUpperCase();

    str = str
        .toLowerCase()
        .replace(/(^\w{1})|([\s']+\w{1})/g, toUpperCaseFn)
        .replace(/([^a-z]{1}[a-z]{1}[^a-z]{1})/ig, toUpperCaseFn); // eg the "P" in S&P500
    str = str.replace(/(\s{1}|^)(esg|etf|msci|us)(\s|$)/ig, function ($1) { return $1.toUpperCase(); });
    return str;
}
