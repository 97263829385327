/*eslint react-hooks/exhaustive-deps: off */
import React, { memo } from "react";
import classnames from "classnames";

import "./Switcher.css";

const MemoSpan = memo(function Span() {
    return <span />;
});

export default function Switcher(props: ISwitcherProps) {
    return (
        <div className="switcher" data-count={props.options.length}>
            <div className="inner">
                {props.options.map((option: any) =>
                    <label key={option.value + option.label}
                        className={classnames(
                            {active : String(option.value) === String(props.value)}
                        )}    
                        onClick={() => props.setter(option.value)}>
                        {option.label || option.value}
                    </label>
                )}
                <MemoSpan />
            </div>
        </div>
    );
}

interface ISwitcherOption {
    label?: string;
    value: any;
}

interface ISwitcherProps {
    options: ISwitcherOption[];
    value: any;
    setter: (value: any) => void;
}