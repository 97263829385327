/*eslint react-hooks/exhaustive-deps: off */
import React, { useState, useRef, useEffect } from "react";
import { useDarkMode } from 'usehooks-ts';

import { devLog } from 'common/Utils';
import useQuotesData, { getFilteredDataTable } from 'depot/hooks/useQuotesData';
import useDepotData from 'depot/hooks/useDepotData';
import Tabs from 'common/components/Tabs';
import Switcher from 'common/components/Switcher';
import { LineChart } from 'common/components/Chart';

export default function ChartDev() {
    devLog("ChartDev");

    const { isDarkMode } = useDarkMode();
    const depotData = useDepotData();
    const quotesData = useQuotesData();
    const chart: any = useRef();
    const [filterDuration, setFilterDuration]: any = useState(30);

    useEffect(() => {
        refreshChart(chart, quotesData, depotData, filterDuration, isDarkMode);
    });

    return (
        <section className="hideIfNoStock">
            <header>
                <Tabs headline="Development" />
                <Switcher options={getHeaderOptions()} value={filterDuration} setter={setFilterDuration} />
            </header>
            <LineChart ref={chart} isLoading={!quotesData?.stocks} />
        </section>
    );
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function refreshChart(chart: any, quotesData: any, depotData: any, filterDuration: number, isDarkMode: boolean) {
    if (chart?.draw && quotesData?.stocks) {
        chart.draw(
            getFilteredDataTable(quotesData, depotData, filterDuration),
            getChartConfig(isDarkMode)
        );
    }
};

function getHeaderOptions() {
    return [
        { value: 30, label: "1m" },
        { value: 90, label: "3m" },
        { value: 182, label: "6m" },
        { value: 365, label: "1y" },
        { value: 365 * 5, label: "5y" },
    ];
}

function getChartConfig(isDarkMode: boolean) {
    const devChartOptions: any = {
        width: "100%",
        height: 400,
        chartArea: {
            top: 10,
            bottom: 25,
            left: 15,
            right: 80,
        },
        focusTarget: "category",
        backgroundColor: "transparent",
        legend: {
            position: "none",
        },
        annotations: {
            textStyle: {
                fontSize: 9,
            },
            datum: {
                stem: {
                    length: 0,
                },
            },
        },
        series: Array(11).fill({ targetAxisIndex: 1 }),
        vAxes: {
            1: {
                format: "percent",
            },
        },
        tooltip: {
            isHtml: true,
        },
        interpolateNulls: true,
    };

    if (isDarkMode) {
        devChartOptions.hAxis = { textStyle: { color: "#DDD" } };
        devChartOptions.vAxis = devChartOptions.hAxis;
        devChartOptions.annotations.textStyle.color = "#DDD";
        devChartOptions.series[2].dataOpacity = 0.3;
    }

    return devChartOptions;
}
