/*eslint @typescript-eslint/no-use-before-define: ["error", { "functions": false, "allowNamedExports": true }]*/
import React, { forwardRef, useEffect } from "react";

export {
    LineChart,
    ComboChart,
    BarChart,
    TreeMapChart,
}

const BaseChart = forwardRef((props: any, chartRef: any) => {
    useEffect(() => {
        if (!chartRef.googleChart) {
            const googleVisualizationClass: any = window.google.visualization[props.type];
            chartRef.googleChart = new googleVisualizationClass(chartRef.current);
            chartRef.draw = (...args: any) => chartRef.googleChart.draw(...args);
        }
    });

    return <div ref={chartRef} className={props?.isLoading ? "loading" : ""}></div>
});

const LineChart = forwardRef((props: any, ref: any) => <BaseChart ref={ref} type="LineChart" {...props} />);
const ComboChart = forwardRef((props: any, ref: any) => <BaseChart ref={ref} type="ComboChart" {...props} />);
const BarChart = forwardRef((props: any, ref: any) => <BaseChart ref={ref} type="BarChart" {...props} />);
const TreeMapChart = forwardRef((props: any, ref: any) => <BaseChart ref={ref} type="TreeMap" {...props} />);
