import { useLocalStorage } from 'usehooks-ts';

import useDepotData from 'depot/hooks/useDepotData';
import { useEffect } from 'react';

export default function useVisitedKeyStorage() {
    const [visitedKeys, setVisitedKeys]: any = useLocalStorage("visitedKeys", []);
    const depotData = useDepotData( );
    
    // LEGACY - todo: remove in may '23
    const [LEGACYportfoliokeys, setLEGACYportfoliokeys] = useLocalStorage("portfoliokeys", "");
    const LEGACYtryToMigrateOldKeys = () => {
        if (!visitedKeys?.length && LEGACYportfoliokeys) {
            Object.keys(LEGACYportfoliokeys).forEach((key: any) => {
                visitedKeys.push({
                    title: LEGACYportfoliokeys[key],
                    key: key,
                });
            });
            setLEGACYportfoliokeys("");
            setVisitedKeys(visitedKeys);
        }
    }


    const addCurrentKey = () => {
        if (depotData && depotData.key && !depotData?.errors?.length) {
            // turn saved "s"-key into current "full"-key
            if (!depotData.isShared) {
                const foundSharedKey = visitedKeys.find((visitedKey : any) => depotData.key !== visitedKey.key && depotData.key.startsWith(visitedKey.key.replace(/^s/, "")));
                if (foundSharedKey) {
                    foundSharedKey.key = depotData.key;
                    setVisitedKeys(visitedKeys);
                }
            }

            // check if key already exists as "s" or "full" key
            const isKeyExisting = visitedKeys.some((visitedKey : any) => visitedKey.key.replace(/^s/, "").startsWith(depotData.key.replace(/^s/, "")));

            // if not existing, just add key
            if (!isKeyExisting) {
                visitedKeys.push({
                    title: depotData.title,
                    key: depotData.key,
                });
                
                // sort
                visitedKeys.sort((a : any, b : any) => a.title.localeCompare(b.title));
    
                setVisitedKeys(visitedKeys);
            }
        }
    }

    useEffect(() => {
        LEGACYtryToMigrateOldKeys();
        addCurrentKey();
    }, [depotData, visitedKeys]);

    return visitedKeys;
}
