import React, { useEffect } from 'react';
import { Outlet, useParams } from "react-router-dom";
import { useDarkMode } from 'usehooks-ts';
import '@coreui/coreui/dist/css/coreui.min.css'

import './AppLayout.css';
import AppHeader from "app/AppHeader";
import useVisitedKeyStorage from 'common/useVisitedKeyStorage';
import useDepotData from 'depot/hooks/useDepotData';

export default function AppLayout() {
    const { isDarkMode, disable } = useDarkMode();    
    useEffect(() => {
        if (isDarkMode) {
            disable(/*darkmode*/);
        }
    });

    // next two lines are crucial ! Here loading and saving happens
    const { peerfolioKey } = useParams();
    useDepotData(peerfolioKey);
    useVisitedKeyStorage();

    // (isDarkMode ? " darkModeDetectionEnabled" : "")
    
    return (
        <div className={"App"}>
            <AppHeader />
            
            <main>            
                <Outlet />

                <section>
                    <div className="credits">
                        <a href="https://peerfol.io" target="_blank" rel="noreferrer">peerfol.io</a>
                        <span> | Show ♥ by recommending</span>
                    </div>
                </section>
            </main>
        </div>
    )
}
