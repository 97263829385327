/*eslint react-hooks/exhaustive-deps: off */
import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import classnames from "classnames";

import "./StockTable.css";
import { formatNumber, getRedBlackGreenColorForNumber, ucwords, formatDuration } from "common/Utils";
import useDepotData from 'depot/hooks/useDepotData';
import StockDetails from "depot/components/StockDetails";
import Tabs from 'common/components/Tabs';
import Switcher from 'common/components/Switcher';
import Filter from 'depot/components/StockFilter';

export default function StockTable() {
    const [stock, setStock]: any = useState(null);
    const depotData = useDepotData();
    const [displayType, setDisplayType] = useState("fit");
    const tableConfig: any = React.useMemo(
        () => getTableConfig(depotData, displayType === "fit"),
        [depotData.stocks, displayType]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable(tableConfig, useSortBy);

    return (
        <section className="depotSection">
            <header className="hideIfNoStock">
                <Tabs headline="List" />
                <Switcher options={getHeaderOptions()} value={displayType} setter={setDisplayType} />
            </header>

            {depotData?.stocks?.length ?
                <div>
                    <Filter />

                    <div className={"tableWrapper hideIfNoStock displayType_" + displayType}>
                        <table className={"depot"} {...getTableProps()}>

                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column: any) => (
                                            <th {...column.getHeaderProps([
                                                column.getSortByToggleProps(),
                                                {
                                                    className: classnames(column.id, {
                                                        sortcolumn: column.isSorted,
                                                        isSortedDesc: column.isSortedDesc,
                                                    }),
                                                }
                                            ])}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {rows.map((row: any) => {
                                    prepareRow(row)
                                    if (!row?.values?.hidden) {
                                        return (
                                            <tr {...row.getRowProps([{
                                                className: classnames({
                                                    watchlist: !row?.original?.count,
                                                    isDataOld: row?.original?.isDataOld,
                                                    isDataFromYesterday: !row?.original?.isDataFromToday,
                                                }),
                                                onClick: () => setStock({ ...row.original }),
                                            }])}>
                                                {row.cells.map((cell: any) => (
                                                    <td {...cell.getCellProps([{
                                                        className: classnames(cell.column.id,
                                                            cell.column.getClassName ? cell.column.getClassName(cell) : "", 
                                                            {
                                                                sortcolumn: cell.column.isSorted,
                                                            }
                                                        ),
                                                        title: (cell.column.getCellTitle ? cell.column.getCellTitle(cell) : ""),
                                                        style: (cell.column.getCellStyle ? cell.column.getCellStyle(cell) : {}),
                                                    },
                                                    ])}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                                )}
                                            </tr>
                                        )
                                    }
                                    return <></>    // just in case
                                })}
                            </tbody>

                            <tfoot>
                                {footerGroups.map(footerGroup => (
                                    <tr {...footerGroup.getFooterGroupProps()}>
                                        {footerGroup.headers.map((column: any) => (
                                            <th {...column.getFooterProps([
                                                {
                                                    className: classnames(column.id, {
                                                        sortcolumn: column.isSorted,
                                                    }),
                                                    style: (column.getFooterStyle ? column.getFooterStyle(column) : {}),
                                                },
                                            ])}>
                                                {column.render("Footer")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </tfoot>

                        </table>
                        {stock?.name}
                        <StockDetails stock={stock} setStock={setStock} />
                    </div>
                </div>
                :
                <Filter />
            }
        </section>
    )
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function getTableConfig(depotData: any, isSmallDisplay: boolean = false) {
    return {
        columns: getColumnConfig(depotData, isSmallDisplay),
        data: depotData.stocks,
        initialState: { sortBy: [{ id: "valueDiffAbs" }] },
        disableSortRemove: true,    //only allow Asc,Desc and disabled unsorted 3rd state
        disableMultiSort: true,
        defaultColumn: {
            sortType: "basic",
        },
        autoResetSortBy: false,
    };
}

function getColumnConfig(depotData: any, isSmallDisplay: boolean = false) {
    return [
        {
            accessor: "iterator",
            Header: " ",
            disableSortBy: true,
        },
        {
            accessor: "name",
            Header: "Name",
            Cell: (cellObj: any) => (isSmallDisplay ? cellObj.value : cellObj.row.original.fullName),
        },
        {
            accessor: "category",
            Header: "Category",
        },
        {
            accessor: "note",
            Header: "Note",
            getCellTitle: (cellObj: any): string =>
                (cellObj.row.original.note ? cellObj.row.original.note.replace(/'/gi, "\"") : ""),
        },
        {
            accessor: "type",
            Header: "Type",
            Cell: (obj: any) => ucwords(obj.value),
        },
        {
            accessor: "market",
            Header: "Market",
        },
        {
            accessor: "dateAge",
            Header: "DataAge",
            Cell: (obj: any) => formatDuration(obj.value),
        },
        {
            accessor: "buyDate",
            Header: "BuyDate",
            Cell: (cellObj: any) => (cellObj.value ? cellObj.value.toISOString().substr(0, 10) : ""),
        },
        {
            accessor: "count",
            Header: "BuyCount",
            Cell: (cellObj: any) => formatNumber(cellObj.value),
        },
        {
            accessor: "buyPrice",
            Header: "BuyPrice",
            Cell: (cellObj: any) => formatNumber(cellObj.value, { type: cellObj.row.original.currency || (isSmallDisplay ? undefined : depotData.currency), decimals: 2 }),
        },
        {
            accessor: "buyValue",
            Header: "BuyValue",
            Cell: (cellObj: any) => formatNumber(cellObj.value, { type: depotData.currency, decimals: 0, useSuffix: isSmallDisplay }),
            Footer: (_columnObj: any) => (
                <span className={depotData.isShared ? "shareDepotLabel" : ""}>
                    {formatNumber(depotData.buyValue, { type: depotData.currency, decimals: 0, useSuffix: isSmallDisplay })}
                </span>
            ),
        },
        {
            accessor: "limitBottom",
            Header: "Limit",
            getClassName: (cellObj: any): string => (cellObj.row.original.isBelowLimit ? " highlight" : ""),
            Cell: (cellObj: any) =>
                (cellObj.value ? formatNumber(cellObj.value, { type: cellObj.row.original.currency || (isSmallDisplay ? undefined : depotData.currency), decimals: 2 }) : ""),
        },
        {
            accessor: "limitTop",
            Header: "Limit",
            getClassName: (cellObj: any): string => (cellObj.row.original.isAboveLimit ? " highlight" : ""),
            Cell: (cellObj: any) =>
                (cellObj.value ? formatNumber(cellObj.value, { type: cellObj.row.original.currency || (isSmallDisplay ? undefined : depotData.currency), decimals: 2 }) : ""),
        },
        {
            accessor: "price",
            Header: "Price",
            Cell: (cellObj: any) => <span>{formatNumber(cellObj.value, { type: cellObj.row.original.currency || (isSmallDisplay ? undefined : depotData.currency), decimals: 2 })}</span>,
        },
        {
            accessor: "priceDiffPer",
            Header: "%",
            Cell: (cellObj: any) => <span>{formatNumber(cellObj.value, { type: "%", decimals: 1 })}</span>,
            getCellStyle: (cellObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(cellObj.value * 100),
            }),
            Footer: (_columnObj: any) =>
                formatNumber(depotData.valueDiffPerToday, { type: "%" }),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(depotData.valueDiffAbsToday),
            }),
        },
        {
            accessor: "valueDiffAbsToday",
            Header: "Today",
            Cell: (cellObj: any) => <span>{formatNumber(cellObj.value, { type: depotData.currency })}</span>,
            getCellStyle: (cellObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(cellObj.row.values.priceDiffPer * 100),
            }),
            Footer: (_columnObj: any) => formatNumber(depotData.valueDiffAbsToday, { type: depotData.currency, decimals: 0 }),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(depotData.valueDiffAbsToday),
            }),
        },
        {
            accessor: "valueDiffPer",
            Header: "Abs%",
            Cell: (cellObj: any) => formatNumber(cellObj.value, { type: "%", useSuffix: isSmallDisplay }),
            getCellStyle: (cellObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(cellObj.value),
            }),
            Footer: (_columnObj: any) => formatNumber(depotData.diffPer, { type: "%", useSuffix: isSmallDisplay }),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(depotData.diffAbs),
            }),
        },
        {
            accessor: "valueDiffAbs",
            Header: "Abs",
            Cell: (obj: any) => formatNumber(obj.row.values.value - obj.row.values.buyValue, { type: depotData.currency, useSuffix: isSmallDisplay }),
            getCellStyle: (obj: any): {} => ({
                color: getRedBlackGreenColorForNumber((obj.row.values.value - obj.row.values.buyValue) / depotData.value * 10000)
            }),
            Footer: (_columnObj: any) => formatNumber(depotData.diffAbs, { type: depotData.currency, decimals: 0, useSuffix: isSmallDisplay }),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getRedBlackGreenColorForNumber(depotData.diffAbs),
            }),
        },
        {
            accessor: "value",
            Header: "Value",
            Cell: (obj: any) => formatNumber(obj.value, { type: depotData.currency, useSuffix: isSmallDisplay }),
            Footer: (_columnObj: any) => formatNumber(depotData.value, { type: depotData.currency, decimals: 0, useSuffix: isSmallDisplay }),
        },
    ];
}

function getHeaderOptions() {
    return [
        { value: "fit", label: "fit" },
        { value: "full", label: "full" },
    ];
}
