import React from 'react';
import { useParams } from "react-router-dom";
import { useWindowSize } from 'usehooks-ts';
import classnames from "classnames";

import useGoogleChartLib from 'depot/hooks/useGoogleChartLib';
import Overview from 'depot/components/Overview';
import StockTable from 'depot/components/StockTable';
import ChartShare from 'depot/components/ChartShare';
import ChartPnl from 'depot/components/ChartPnl';
import ChartHistory from 'depot/components/ChartHistory';
import ChartDev from 'depot/components/ChartDev';
import useDepotData from 'depot/hooks/useDepotData';
import usePeerfolioStore from "common/Store";

export default function DepotPage() {
    const isGoogleChartReady = useGoogleChartLib();
    const { peerfolioKey } = useParams();
    const depotData = useDepotData(peerfolioKey);
    const filterTerm: any = usePeerfolioStore((state: any) => state.filterTerm);
    useWindowSize();

    const isReady = depotData && depotData?.stocks && isGoogleChartReady;
    if (!isReady) {
        return <div className="loading" />
    } else if (depotData?.errors?.length) {
        return <div>Error</div>
    }

    return (
        <div className={classnames({
            noStocks: depotData?.stocks?.length === 0,
            filterActive: filterTerm,
        })}>
            <Overview />
            <StockTable />
            <ChartShare />
            <ChartPnl />
            <ChartDev />
            <ChartHistory />
        </div>
    );
}
