import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

export default function useGoogleChartLib() {
    const [isGoogleChartReady, setGoogleChartReady] = useState(false);

    useEffectOnce(() => {
        if (window.google && window.google.charts) {
            window.google.charts
                .load('current', { 'packages': ["corechart", "treemap", "bar", "line"] })
                .then(() => setGoogleChartReady(true));
        }
    });

    return isGoogleChartReady;
}

/**
 * Fix to work with Window object in typescript
 * https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
 */
declare global {
    interface Window {
        google: any,
    }
}
