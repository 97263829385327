/*eslint react-hooks/exhaustive-deps: off */
import React, { useState, useRef } from "react";
import { useDarkMode } from 'usehooks-ts';

import useHistoryData, { getFilteredDataTable } from 'depot/hooks/useHistroyData';
import { sanitizeName } from 'depot/hooks/useDepotData';
import { ComboChart } from 'common/components/Chart';
import { devLog } from 'common/Utils';
import Tabs from 'common/components/Tabs';
import DropDown from 'common/components/DropDown';
import usePeerfolioStore from "common/Store";

export default function ChartHistory() {
    devLog("ChartHistory");

    const { isDarkMode } = useDarkMode();
    const chart = useRef();
    const [filterDuration, setFilterDuration]: any = useState(90);
    const [filterStockId, setFilterStockId]: any = useState(0);
    const [filterType, setFilterType]: any = useState("abs");
    const filterTerm: any = usePeerfolioStore((state: any) => state.filterTerm);

    const historyData = useHistoryData(filterStockId);
    if (historyData?.rows) {
        refreshChart(
            chart,
            getFilteredDataTable(historyData, filterDuration, filterType, (filterStockId > 0), filterStockId),
            isDarkMode
        );
    }

    return (
        <section>
            <header>
                <Tabs headline={"History" + (filterTerm ? " (not filtered)" : "")} />

                <div style={{ display: "flex" }}>
                    <DropDown key="filterType" options={getFilterTypeOptions()} value={filterType} setter={setFilterType} />
                    <DropDown key="filterStockId" options={getFilterStockIdOptions(historyData)} value={filterStockId} setter={setFilterStockId} />
                    <DropDown key="filterDuration" options={getFilterDurationOptions(historyData)} value={filterDuration} setter={setFilterDuration} />
                </div>
            </header>
            <ComboChart ref={chart} isLoading={!historyData?.rows} />
        </section>
    );
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function refreshChart(chart: any, dataTable: any, isDarkMode: boolean) {
    if (chart?.draw && dataTable?.getNumberOfRows()) {
        chart.draw(dataTable, getHistoryChartConfig(isDarkMode));
    }
}

function getFilterDurationOptions(historyData: any) {
    const options: any = [
        { value: 7, label: "7d", },
        { value: 30, label: "1m", },
        { value: 90, label: "3m", },
    ];

    if (!historyData?.rows) {
        return options;
    }

    const dates: any = Object.keys(historyData.rows);
    if (dates) {
        const dateMin = new Date(dates[0]);
        const dateMax = new Date(dates.pop());
        const dayRange = Math.floor((dateMax.getTime() - dateMin.getTime()) / 1000 / 60 / 60 / 24);
        if (dayRange > 85) {
            options.push({ value: 180, label: "6m", });
        }
        if (dayRange > 175) {
            options.push({ value: 365, label: "1y", });
        }

        const minYear = dateMin.getFullYear();
        const maxYear = dateMax.getFullYear();
        if (minYear !== maxYear) {
            options.push({ label: "———", options: [] });

            for (let y = minYear; y <= maxYear; y += 1) {
                options.push({ value: y, label: y, });
            }

            options.push({ value: 10000, label: "max", });
        }
    }

    return options;
}

function getFilterStockIdOptions(historyData: any) {
    const options: any = [
        { value: 0, label: "All", },
    ];

    if (!historyData?.stockFilterAvailable) {
        return options;
    }

    if (Object.keys(historyData.transactions).length) {
        options.push({ value: 0, key: "0-withalltrades", label: "All (incl Trades)", });
    }

    const stockOptionGroups: any = [
        { label: "Current", options: [] },
        { label: "Old", options: [] },
    ];

    const stocksEntries: any = Object.entries(historyData.stockFilterAvailable).sort((a: any, b: any) => a[1] > b[1] ? 1 : -1);
    for (const [stockId, name] of stocksEntries) {
        // TODO: add to backend the information if stock is current or not
        const isOldStock = (Math.random() < 0.5);
        stockOptionGroups[Number(isOldStock)].options.push({ value: stockId, label: sanitizeName(name || stockId), });
    }
    stockOptionGroups
        .filter((optGroup: any) => optGroup.options.length)
        .forEach((optGroup: any) => options.push(optGroup));

    return options;
}

function getFilterTypeOptions() {
    return [
        { value: "abs", label: "Abs", },
        { value: "rel", label: "Rel", },
    ];
}

function getHistoryChartConfig(isDarkMode: boolean) {
    const historyChartConfig: any = {
        width: "100%",
        height: 400,
        hAxis: {
            textStyle: {
                fontSize: 12,
            }
        },
        backgroundColor: "transparent",
        animation: {
            duration: 600,
            easing: "inAndOut",
        },
        focusTarget: "category",
        chartArea: {
            top: 10,
            bottom: 40,
            left: 40,
            right: 40,
        },
        tooltip: {
            isHtml: true,
        },
        isStacked: "absolute",
        vAxes: [
            {
                format: "short",
                textStyle: { fontSize: 12, },
            },
            {
                gridlines: { count: 0 },
                format: "percent",
                textStyle: { fontSize: 12 },
            },
        ],
        annotations: {
            datum: {
                stem: {
                    length: 2,
                    color: "transparent",
                }
            },
            style: "point",
            textStyle: {
                fontSize: 8,
                color: "#000000",
                auraColor: "#FFFFFF",
            }
        },
        legend: "none",
        seriesType: "area",
        series: [
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#AAA",    // gray area
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "black",    // buy points
                pointSize: 7,
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#006600",    // green area
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#FFBBBB",    // red area
            },
            {
                targetAxisIndex: 0,
                type: "line",
                lineWidth: 1,
            },
            {
                targetAxisIndex: 1,   // right axis percentage
                type: "line",
                lineWidth: 2,
            },
        ],
    };

    if (isDarkMode) {
        historyChartConfig.hAxis.textStyle.color = "#DDD";
        historyChartConfig.vAxes[0].textStyle.color = "#DDD";
        historyChartConfig.vAxes[0].gridlines = { color: "#444" };
        historyChartConfig.vAxes[0].minorGridlines = { color: "#444" };

        historyChartConfig.vAxes[1].textStyle.color = "#DDD";
        historyChartConfig.vAxes[1].baselineColor = { color: "#AAA" };

        historyChartConfig.series[0].color = "#FFF";
        historyChartConfig.series[1].color = "#00FF00";
        historyChartConfig.series[2].color = "#FF0000";
        historyChartConfig.series[4].lineWidth = 2;
    }

    return historyChartConfig;
}
