import React, { useState } from "react";
import { useDocumentTitle } from 'usehooks-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';
import Select from 'react-select';
import classnames from "classnames";

import './FolioSelector.css';
import useDepotData from 'depot/hooks/useDepotData';
import useVisitedKeyStorage from 'common/useVisitedKeyStorage';
import Tabs from 'common/components/Tabs';
import ToolsShare from 'depot/components/ToolsShare';
import ToolsEdit from 'depot/components/ToolsEdit';

export default function FolioSelector() {
    const [showPopUp, setShowPopUp]: any = useState(false);

    const { peerfolioKey } = useParams();
    const depotData = useDepotData();
    useDocumentTitle("peerfol.io" + (depotData?.title ? " | " + depotData.title : ""));
    const navigate = useNavigate();
    const visitedKeys = useVisitedKeyStorage();
    const options = visitedKeys.map((visitedKey: any) => ({
        value: visitedKey.key,
        label: visitedKey.title,
    }));

    var currentlySelected = options.find((option: any) => option.value === peerfolioKey);
    // special handling if no peerfolio was loaded from visitedKeyStorage
    if (!currentlySelected) {
        currentlySelected = {
            value: peerfolioKey,
            label: depotData?.title || "Loading...",
        };
        options.push(currentlySelected);
    }

    const formatOptionLabel = ({ value, label }: { value: string, label: string }) => (
        <div style={{ display: "flex" }}>
            <div title={value}>{label}</div>
            {value?.startsWith("s") &&
                <div style={{ marginLeft: "10px", background: "red", color: "white" }}>
                    [1K]
                </div>
            }
        </div>
    );

    return (
        <div className="folioSelector_wrapper me-auto p-0">
            <Select
                className={classnames(
                    "folioSelector",
                    {singlePeerfolio : options?.length <= 1}
                )}
                classNamePrefix="folioSelector"
                // defaultMenuIsOpen={true}
                formatOptionLabel={formatOptionLabel}
                isLoading={!depotData || !depotData?.stocks}
                isSearchable={false}
                options={options}
                onChange={
                    (selectedOption: any) => {
                        if (selectedOption.value !== peerfolioKey) {
                            navigate("/" + selectedOption.value);
                        }
                    }
                }
                value={currentlySelected}
            />

            <div
                style={{ visibility: (depotData?.stocks ? "inherit" : "hidden") }}
                className="edit bx bx-share-alt"
                onClick={() => setShowPopUp(true)}
            />

            <CModal visible={showPopUp} onClose={() => setShowPopUp(false)}>
                <CModalHeader>
                    <CModalTitle>Depot features</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <Tabs headline="Share" />
                    <ToolsShare />
                    <hr />
                    <Tabs headline="Edit" />
                    <ToolsEdit />
                </CModalBody>
            </CModal>
        </div>
    )
}
