import { create } from 'zustand'

interface PeerfolioState {
    depotData: any,
    setDepotData: (depotData: {}) => void,

    filterTerm: string,
    setFilterTerm: (filterTerm: string) => void,

    windowSize: any,
    setWindowSize: (windowSize: {}) => void,
}

const usePeerfolioStore = create<PeerfolioState>()((set) => ({
    depotData: null,
    setDepotData: (_depotData: {}) => set({ depotData: _depotData }),

    filterTerm: "",
    setFilterTerm: (_filterTerm: string) => set({ filterTerm: _filterTerm }),

    windowSize: null,
    setWindowSize: (_windowSize: {}) => set({ windowSize: _windowSize }),
}));

export default usePeerfolioStore;
