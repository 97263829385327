/*eslint react-hooks/exhaustive-deps: off */
import React, { useRef, useEffect, useState } from "react";
import { useDarkMode } from 'usehooks-ts';

import { devLog, formatNumber, getRedBlackGreenColorForNumber } from 'common/Utils';
import { TreeMapChart } from 'common/components/Chart';
import useDepotData from 'depot/hooks/useDepotData';
import Tabs from 'common/components/Tabs';
import Switcher from 'common/components/Switcher';

export default function ChartShare() {
    devLog("ChartShare");

    const { isDarkMode } = useDarkMode();
    const depotData = useDepotData();
    const chart = useRef();
    const [selected, setSelected]: any = useState("today");
    const dataTables: any = getDataTables(depotData, isDarkMode); // useMemo(() => getDataTables(depot), [depot.stock]);;

    useEffect(() => {
        refreshChart(chart, dataTables[selected], isDarkMode);
    });

    return (
        <section className="hideIfNoStock">
            <header>
                <Tabs headline="Share" />
                <Switcher options={getHeaderOptions()} value={selected} setter={setSelected} />
            </header>
            <TreeMapChart ref={chart} />
        </section>
    );
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function refreshChart(chart: any, selectedDataTable: any, isDarkMode: boolean) {
    if (chart?.draw && selectedDataTable) {
        const getTooltipFromDataTable = (row: any, _share: any, _color: any) => selectedDataTable.getValue(row, 4);

        chart.draw(
            selectedDataTable,
            getChartConfig(isDarkMode, getTooltipFromDataTable)
        );
    }
}

function getHeaderOptions() {
    return [
        { value: "today" },
        { value: "total" },
        { value: "type" },
        { value: "category" },
    ];
}

function getChartConfig(_isDarkMode: boolean, getTooltip: any) {
    const chartOptions = {
        animation: {
            duration: 300,
            easing: "inAndOut",
        },
        highlightOnMouseOver: true,
        maxDepth: 0,
        headerHeight: 0,
        showScale: false,
        minColorValue: -50,
        maxColorValue: 50,
        fontColor: "#000000",
        noColor: "#F5F5F5",
        minColor: "#ff0000",
        midColor: "#cccccc",
        maxColor: "#009900",
        width: "100%",
        height: 400,
        // forceIFrame: true,  // to prevent tooltip generation in main --> not working because of CSS
        generateTooltip: getTooltip,
    };

    return chartOptions;
}

function getDataTables(depotData: any, isDarkMode: boolean) {
    const rootKey = "Portfolio";

    const dataTables: any = {};
    ["today", "total", "type", "category"].forEach((chartType: any) => {
        dataTables[chartType] = new window.google.visualization.DataTable({
            cols: [
                { type: "string", label: "Label", },
                { type: "string", label: "Parent", },
                { type: "number", label: "Size", },
                { type: "number", label: "Color", },
                { type: "string", label: "Tooltip", },
            ],
            rows: [{ c: [{ v: rootKey }, { v: "" }, { v: 0 }, { v: 0 }, { v: "" }] }],  // add root
        });
    });

    const treeData: any = {
        today: {},
        total: {},
        type: {},
        category: {},
    };

    if (depotData && depotData.stocks) {
        depotData.stocks
            .filter((stock: any) => stock.count > 0)
            .forEach((stock: any) => {
                var label = stock.name;
                if (stock.symbol) {
                    label = (stock.symbol.length < label.length ? stock.symbol : label.toUpperCase());
                }

                treeData.today[label] = treeData.today[label] || { "value": 0, "diff": 0 };
                treeData.today[label].value += stock.value;
                treeData.today[label].diff += (stock.isDataFromToday ? stock.priceDiffAbs * stock.count : null);

                treeData.total[label] = treeData.total[label] || { "value": 0, "diff": 0 };
                treeData.total[label].value += stock.value;
                treeData.total[label].diff += stock.valueDiffAbs;

                treeData.type[stock.type] = treeData.type[stock.type] || { "value": 0, "diff": 0 };
                treeData.type[stock.type].value += stock.value;
                treeData.type[stock.type].diff += stock.valueDiffAbs;

                treeData.category[stock.category] = treeData.category[stock.category] || { "value": 0, "diff": 0 };
                treeData.category[stock.category].value += stock.value;
                treeData.category[stock.category].diff += stock.valueDiffAbs;
            });

        Object.keys(treeData).forEach(treeDataKey => {
            dataTables[treeDataKey].addRows(
                Object.entries(treeData[treeDataKey]).map(treeDataItemEntry => {
                    const [key, value]: any = treeDataItemEntry;
                    const share = value.value / depotData.value * 100;
                    const diffPer = value.diff / (value.value - value.diff) * 100;
                    return [
                        `${key} (${formatNumber(diffPer, { type: "%", includeSign: true })}, ${formatNumber(share, { type: "%" })})`,
                        rootKey,
                        share,
                        diffPer * (treeDataKey === "today" ? 10 : 1),
                        `<div style="padding: 3px; ${isDarkMode ? "background: #222; border: 2px solid #eee;" : "background: #eee; border:1px solid #777;"}">
                        <b>${key}</b><br />
                        Perf:
                        <span style="color: ${getRedBlackGreenColorForNumber(diffPer)}">
                            ${formatNumber(diffPer, { type: "%", includeSign: true })}
                        </span><br />
                        Share: ${formatNumber(share, { type: "%" })}<br />
                    </div>`,
                    ];
                })
            );
        });
    }

    return dataTables;
}
