import React from 'react';

import useDepotData from 'depot/hooks/useDepotData';

export default function ToolsEdit() {
    const comdirectOpen: string = "http://www.comdirect.de/inf/musterdepot/pmd/freunde.html?SORT=PROFIT_LOSS_POTENTIAL_CURRENCY_PORTFOLIO_PCT&SORTDIR=ASCENDING&portfolio_key=";
    const comdirectEdit: string = "https://nutzer.comdirect.de/inf/musterdepot/pmd/meineuebersicht.html?name=";

    const depotData = useDepotData();

    return (
        <div style={{ padding: "15px" }}>
            {depotData && depotData.type === "comdirect" &&
                <div>
                    <a href={comdirectOpen + depotData.key} target="_blank" rel="noreferrer">Open Comdirect</a>
                    <br /><br />
                    <a href={comdirectEdit + depotData.title} target="_blank" rel="noreferrer">Edit Comdirect</a>
                </div>
            }
        </div>
    );
}
