import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AppLayout from 'app/AppLayout';
import DepotPage from 'depot/DepotPage';
import AccountPage from 'account/AccountPage';
import NotFoundPage from 'app/NotFoundPage';
import { devLog } from 'common/Utils';

export default function App() {
    devLog("App");

    const reactRouter = createBrowserRouter([{
        element: <AppLayout />,
        children: [
            { path: ":peerfolioKey", element: <DepotPage />, },
            { path: ":peerfolioKey/account", element: <AccountPage />, },
            { path: "*", element: <NotFoundPage />, },
        ],
    }]);

    return <RouterProvider router={reactRouter} />
}
